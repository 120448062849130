import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SpecificDatePopUp from '../experiences/listings/specificDatePopUp';
import { ImCross } from 'react-icons/im';
import '../../assets/css/partials/holidaylistingDaterange.scss';
import DateRangePopUp from '../experiences/listings/dateRangePopUp';
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: 'fit-content',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
    dialogPaper: {
        height: '55%',
        width: '60%',
        backgroundColor: '#F5F5F5'
    },

}));

export default function Popup({
    openPopup,
    closeModal,
    updatePopupData,
    saveDateData,
    isHolidayListing,
    data,
    IsCorpAddNew
}) {
    let packageScheduleData = data?.schedule_data
    const classes = useStyles();
    const [ isPackages, setIspackages ] = useState(false)
    const [ isDateRange, setIsDateRange ] = useState(false)
    const [ arrData, setArrData ] = useState(data?.schedule_data?.selectedDates || [])
    const [open, setOpen] = useState(false);
    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState('md');
    const [days, setDays] = useState( packageScheduleData?.days);
    const [nights, setNights] = useState( packageScheduleData?.nights);
    const [ isScheduleDateErrorValidation, setIsScheduleDateErrorValidation ] = useState(null)
    const [ dateRangeError, setDateRangeError ] = useState(null)
    const [ isScheduleNightDateErrorValidation, setIsScheduleNightDateErrorValidation ] = useState(null);
    const [isScheduleNightMatchErrorValidation, setIsScheduleNightMatchErrorValidation] = useState(false);
    const [isScheduleDateErrorValidationDayNight, setIsScheduleDateErrorValidationDayNight] = useState(false);
    const [dublicateData,setDublicateDate]=useState(null)
   let selectedDates = [];
   console.log(data, "data------")
    useEffect(() => {
        if(data?.schedule_type === "package"){
            setIspackages(true)
        }else if(IsCorpAddNew){
            setIspackages(true)
        }else{
            setIsDateRange(true)
        }
    }, [data]);

    const packageClickHandler = () => {
       setIspackages(true);
       setIsDateRange(false);
    };

    const dateRangeClickHandler = () => {
        setIsDateRange(true);
        setIspackages(false);
    };

    const addDateRange = (arrayData) => {
        setDublicateDate(null)
        const arr = [...arrData, arrayData]
        let resDate = arr.filter(d => {
            return moment(d.from, 'DD-MM-YYYY'); ;
        });
        resDate.sort((a, b) => moment(a.from, "DD-MM-YYYY").valueOf() - moment(b.from, "DD-MM-YYYY").valueOf());
        if(resDate?.length===1){
            setArrData(resDate);
       
        }else{
            let joinedDate=arrayData.from+arrayData.to
            arrData.map(e=>{
                let joined=e.from+e.to
                if(joinedDate==joined){
                    setDublicateDate(arrayData.id)
                }
            })
            setArrData(resDate);
        }
       
    };
    useEffect(()=>{
        if(dublicateData){
            setArrData(arrData.filter(item => item.id !== dublicateData)); 
        }
    },[dublicateData])
    const deleteAllDate = () => {
        setArrData([])
    }

    const deleteData = (id) => {
        let deleteDateData = arrData.filter(item => item.id !== id);
        setArrData(deleteDateData);
    };

    const saveDate = () => {
        setDateRangeError(false)
        if(arrData.length === 0){
            setDateRangeError(true)
        }else{
            saveDateData(arrData, "date_range");
            setOpen(closeModal);
        }
    }
    const handleClickOpen = () => {
        setOpen(closeModal);
    };

    const handleClose = () => {
        setOpen(closeModal);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(event.target.value);
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };

    const handleDaysCount = (event) => {
        setIsScheduleDateErrorValidation(false)
        const days = event.target.value
        setDays(days)
        if(days <0)setIsScheduleDateErrorValidation(true);
        if(days==0)setIsScheduleDateErrorValidation(true);
        if(days>0){
            setNights(`${days-1}`)
            if(nights < 0)setIsScheduleNightDateErrorValidation(true)

        }
        if(!days){
            setNights(" ")
        }
      
    }
   
    // const handleNightsCount = (event) => {
    //     setIsScheduleNightDateErrorValidation(false)
    //     const nights = event.target.value
    //     setNights(nights)
    //     if(nights < 0)setIsScheduleNightDateErrorValidation(true)
    // }

    const upDateDaysAndNightsHandler = () => {
        if(parseInt(days)-parseInt(nights)>1 || parseInt(days)-parseInt(nights)<-1){
            setIsScheduleNightMatchErrorValidation(true);
            return false;
        }
        if(!days || nights<0){
           
            setIsScheduleDateErrorValidationDayNight(true);
            return false;
        }
        if(days <=0)setIsScheduleDateErrorValidation(true);
        else if (nights < 0)setIsScheduleNightDateErrorValidation(true)
        else{
            updatePopupData(days, nights, "package")
        }
        
    }
   
    return (
        <React.Fragment>
           
            <Dialog
                maxWidth={maxWidth}
                open={openPopup}
                onClose={closeModal}
                aria-labelledby="max-width-dialog-title"
                classes={{ paper: classes.dialogPaper }}
                PaperProps={{
                    style: {
                        height:"auto"
                    },
               }}
            >
                <DialogTitle id="max-width-dialog-title" style={{ marginLeft: '25px' }}>Add Schedule</DialogTitle>
                <DialogContent>
                    {isHolidayListing && 
                    <button className={`${isDateRange === true? "date-range-active": "date-range-btn"}` } onClick={dateRangeClickHandler}>
                    <span>DateRange</span>
                    </button>
                    }
                    <button className={`${isPackages === true? "date-range-active": "date-range-btn"}` }  onClick={packageClickHandler}>
                        <span>Packages</span>
                    </button>
                    {isPackages &&
                    <DialogContent>
                        {isScheduleDateErrorValidationDayNight && <p style={{color:"red"}}>Please select No of Days/Nights</p>}
                        <div style={{ display: "flex" }}>
                            
                            <div style={{ backgroundColor: 'white', marginTop: '10px', padding: '15px', borderRadius: '10px' }} className="form-group form-custom">
                                <label for="">{`${days?.length > 0? "No of Days": "No of Day"}`}</label>
                                <input
                                    type="number"
                                    placeholder="Days"
                                    value={days}
                                    min='1'
                                    className="form-control"
                                    onChange={handleDaysCount}
                                />
                                {isScheduleDateErrorValidation && <p style={{color:"red"}}>Days should not be negative value or zero</p>}
                                <label style={{ marginTop: '10px' }}>No of Nights </label>
                                <input
                                    type="number"
                                    placeholder="Nights"
                                    value={nights}
                                    min='0'
                                    className="form-control"
                                    // onChange={handleNightsCount}
                                    disabled={true}
                                />
                                {isScheduleNightDateErrorValidation && <p style={{color:"red"}}>Nights should not be negative value</p>}
                                {isScheduleNightMatchErrorValidation && <p style={{color:"red"}}>No of Days/Nights doesn't match</p>}
                                
                            </div>
                            <div
                                style={{ width: '24%', height: 'max-content', marginLeft: '50px', background: 'white', padding: '10px', borderRadius: '10px', marginTop: '10px' }}
                                className="form-group form-custom"
                            >
                                <div >
                                    <label style={{ fontSize: '12px', fontWeight: '400', color: '#000000', opacity: '0.4', marginBottom: '12px' }}> Schedule</label>
                                    <div style={{ marginBottom: '10px', fontWeight: '600', fontSize: '13px' }}>
                                        {days > 1 ? `${days} days` : `${days === undefined ? '' : days} day`},
                                        {nights > 1 ? `${nights} nights` : `${nights === undefined ? '' : nights} night`}
                                    </div>
                                    <div style={{ height: '1px', backgroundColor: 'grey' }}></div>
                                    <button onClick={upDateDaysAndNightsHandler} style={{ marginTop: '10px', height: '38px', width: '80px', backgroundColor: '#2196F3', color: 'white', borderRadius: '5px', border: 'none' }}>
                                        <span>Done</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    }
                    {isHolidayListing &&
                    isDateRange && 
                    <>
                        {dateRangeError && <p style={{color:"red", margin: "9px 0px 0px 0px"}}>Please select date range </p>}
                        {dublicateData && <p style={{color:"red", margin: "9px 0px 0px 0px"}}>This daterange already added </p>}
                       <div className='row date-range_container' >
                           <div className="col-sm-4 date-range">
                             <DateRangePopUp
                                dateData={addDateRange}
                                isHolidayListing={true}
                             />
                           </div>
                           <div className="col-sm-4" style={{marginLeft:"28%"}}>
                               <div className="schedule-container">
                                <div className="schedule-containet">
                               <span>Schedule</span>
                                {arrData && arrData.map(item => {
                                return(
                                    <div>
                                        <p>{`${item.from} - ${item.to}`}</p><span className="cross-icon" onClick={() => deleteData(item.id)}><ImCross/></span> 
                                        {/* <p>{`${moment(item.from).format("Do MMMM")} - ${item.to}`}</p><span className="cross-icon" onClick={() => deleteData(item.id)}><ImCross/></span>  */}
                                        {/* <p>{moment(item.from).format("Do MMMM")+"-"+moment(item.to).format("Do MMMM")+" "}
                                        </p> */}
                                        {/* <span className="cross-icon" onClick={() => deleteData(item.id)}><ImCross /></span> */}
                                        {/* <p>{moment(item.from).format("Do MMMM")+"-"+moment(item.to).format("Do MMMM")+" "}
                                        <span className="cross-icon" onClick={() => deleteData(item.id)}><ImCross /></span>
                                        </p> */}
                                    </div>
                                )})}
                                </div>
                               <hr style={{width:"90%"}}/>
                               <div className="btn-container">
                                  <button className="delete-btn" onClick={deleteAllDate}>Delete</button> 
                                  <button className="save-btn" onClick={ saveDate}>Save</button> 
                               </div>
                               </div>
                           </div>
                       </div>
                       </>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};
