import React, { useState, useRef, useEffect } from "react";
import Menu from "@material-ui/core/Menu";
import Edit from "../../../assets/images/edit.svg";
import PinLocation from "../../../assets/images/pin.svg";
import { useSelector, useDispatch } from "react-redux";
import Popup from "../../popup/popup";
import SchedulePopUp from "../../experiences/listings/addNewSchedulePopUp";
import { FaFileUpload } from "react-icons/fa";
import JoditEditor from "jodit-react";
// import ImageMultiSelect from "../../utilities/ImageMultiSelect/ImageMultiSelect";
import ImageMultiSelect from "../../../common/imageMultiSelect/imageMultiSelect";
import PlusIcon from "../../../assets/images/plusIconSquare.svg";
import GoogleMapModal from "../../utilities/GoogleMap/MapModal";
import Button from "@material-ui/core/Button";
import * as bookingAction from "../../../actions/bookingAction";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDown from "../../../assets/images/arrowDown.svg";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import DefaultImage from "../../../assets/images/plain-background.jpeg";
import "../../../assets/css/partials/addCorpPackageDetails.scss";
import "../../../assets/css/partials/packageDetails.scss";
import "../../../assets/css/partials/layout.scss";
import * as corpListingAction from "../../../actions/corpListingAction";
import { useSnackbar } from "notistack";
import LinearProgress from '@material-ui/core/LinearProgress';
import { countryList } from '../../../common/countryList';
import ToggleButton from '../../holidays/listings/toggle';
import OpeningSoonCalander from "../../holidays/listings/openingSoonCalander";
import Dropdown from "../../../common/uiElements/dropdown";
import Error from "../../../common/error";
import ToggleGroup from "../../../common/uiElements/toggelGroup";
import MinMaxInput from "../../../common/uiElements/minMaxInput";
import ScheduleBoxItinerary from "../../holidays/listings/scheduleBoxItinerary";
import PackTypeButtons from "../../../common/uiElements/packTypeButtons";
import Input from "../../../common/uiElements/input";
import BlockDatePopup from "../../popup/blockDatePopup";
import AddHandleRate from "../../../common/addRateModel";
import { Tabs } from "antd";
import {
  SECTION_NAME,
  PACKAGE_TITLE_EMPTY_ERROR_VALIDATION,
  PACKAGE_TITLE_LENGTH_ERROR_VALIDATION,
  IMAGE,
  LOCATION_EMPTY,
  LOCATION_EMPTY_LENGTH,
  POINTS_EMPTY,
  POINTS_EMPTY_LENGTH,
  SCHEDULE,
  BRIEF_DESCRIPTION_EMPTY,
  BRIEF_DESCRIPTION_EMPTY_LENGTH,
  VALID_TILL,
  DESCRIPTION,
  THUMBNAIL_IMAGE_ERROR,
  REVISED_PRICE_LENGTH,
  REVISED_PRICE_ERROR,
  LOCATION_TYPE_EMPTY,
  LOCATION_TYPE_CHAR_LIMIT,
  ACTIVITY_TYPE_EMPTY,
  ACTIVITY_LEVEL_EMPTY,
  AGES_EMPTY,
  FEATURED_AMENITIES_ERROR,
  GUEST_ERROR,
  CITY_ERROR,
  COUNTRY_ERROR
} from '../../../utils/constants';
import { 
  scheduleDaysVal, 
  scheduleNightsVal,
  scheduleHoursVal,
  scheduleMinutesVal,
  checkFeaturedAmenitiesLength,
  focusInput,
  ACTIVITY_LEVEL_VALUE, 
} from '../../../utils/index';
import {FEATURED_AMENITIES_LIST, ROOM_AMENITIES_LIST, ACTIVITY_OPTIONS, ACTIVITY_LEVEL_OPTIONS, RESORT_FACILITIES_LIST} from '../../../resources/packageData'
import Autocomplete from "../../../common/autoComplete";
import FeaturedPackage from "../../../common/featuredPackage";
import moment from "moment";
import SecondLocation from "../../../common/secondLocation";
import CkEditor from '../../../common/ckEditor/ckeditor'

const { TabPane } = Tabs;
const PurpleSwitch = withStyles({
  switchBase: {
    color: "white",
    "&$checked": {
      color: "#2196F3",
    },
    "&$checked + $track": {
      backgroundColor: "#2196F3",
      opacity: 1,
    },
  },
  checked: {},
  track: {},
})(Switch);

let scheduledData = null;

export default function AddCorporatePackageDetails() {
  const [imageSelect, setImageSelect] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [locationSelect, setLocationSelect] = useState(false);
  const [isError, setIsError] = useState({ error: false, msg: "", name: "" })
  const [popupSelect, setPopupSelect] = useState(false);
  const [content, setContent] = useState("");
  const [select, setSelect] = React.useState(null);
  const [isEdit, setIsEdit] = useState(false);
  // const [isGiftVoucher, setIsGiftVoucher] = useState(false);
  const [isGiftVoucher, setIsGiftVoucher] = useState(null);
  const [dropdownValue, setDropdownValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scheduleType, setScheduleType] = useState("");
  const[addHandleRate,setAddHandleRate]=useState(false)
  const[revisePrice,setRevisedPrice]=useState("")

  const [symbolValidation,setSymbolValidation]=useState(false);
  const[symbolErrorMsg,setSymbolErrorMsg]=useState()

  const [isFeaturedErrorValidation, setIsFeaturedErrorValidation] = useState(false);
  const [isCorpAddSecnameErrorValidation, setIsCorpAddSecnameErrorValidation] = useState(null);
  const [isCorpAddPackTitleEmptyErrorValidation, setIsCorpAddPackTitleEmptyErrorValidation] = useState(null);
  const [isCorpAddPackTitleLengthErrorValidation, setIsCorpAddPackTitleLengthErrorValidation] = useState(null);
  const [isCorpAddImgErrorValidation, setIsCorpAddImgErrorValidation] = useState(null);
  const [isCorpAddImgThumbnailErrorValidation, setIsCorpAddImgThumbnailErrorValidation] = useState(null);
  const [isCorpAddPointsEmptyErrorValidation, setIsCorpAddPointsEmptyErrorValidation] = useState(null);
  const [isCorpAddPointsLengthErrorValidation, setIsCorpAddPointsLengthErrorValidation] = useState(null);
  const [iscorpAddBrigDescEmtyErrorValidation, setIscorpAddBrigDescEmtyErrorValidation] = useState(null);
  const [iscorpAddBrigDescLengthErrorValidation, setIscorpAddBrigDescLengthErrorValidation] = useState(null);
  const [iscoprAddLocationEmptyErrorvalidation, setIscoprAddLocationEmptyErrorvalidation] = useState(null);
  const [iscoprAddLocationLengthErrorvalidation, setIscoprAddLocationLengthErrorvalidation] = useState(null);
  const [iscorpAddScheduleErrorValidation, setIscorpAddScheduleErrorValidation] = useState(null);
  const [iscorpAddValidTillErrorValidation, setIscorpAddValidTillErrorValidation] = useState(null);
  const [iscorpAddDesErrorValidation, setIscorpAddDesErrorValidation] = useState(null);
  const [blockDates_Range,setBlockDates_range]=useState()
  const [popupDateBlock,setPopupDateBLock]=useState(false)
  const[block_date_range,setBlock_date_range]=useState()

  //min and max points valodation
  const [giftVoucherMinPointsEmptyError, setGiftVoucherMinPointsEmptyError] = useState(null);
  const [giftVoucherMaxPointsEmptyError, setGiftVoucherMaxPointsEmptyError] = useState(null);
  const [giftVoucherMaxLengthExceedError, setGiftVoucherMaxLengthExceedError] = useState(null);
  const [giftVoucherMinLengthExceedError, setGiftVoucherMinLengthExceedError] = useState(null);
  const [giftVoucherMinValLess500Error, setGiftVoucherMinValLess500Error] = useState(null);
  const [giftVoucherMaxAvgValError, setGiftVoucherMaxAvgValError] = useState(null);
  const [giftVoucherMinPointsMod500Error, setGiftVoucherMinPointsMod500Error] = useState(null);
  const [giftVoucherMaxPointsMod500Error, setGiftVoucherMaxPointsMod500Error] = useState(null);
  const [openingSoon, setOpeningSoon] = useState(false);
  const [countryError, setCountryError] = useState(null);

  const [priceList, setPriceList] = useState({
    adults: [{adult1:null}],
    children: [{child1:null}]
  });

  const handleAddPrice=()=>{

    const allValuesUpdated = Object.values(priceList).every(categoryArray =>
      categoryArray.every(item =>
        Object.values(item).every(value => {
          const isValid = value !== null && !isNaN(value);
          return isValid;
        })
      )
    );
    if (!allValuesUpdated) {
      enqueueSnackbar("Please fill all the fields", {
        variant: "error",
      });
    } else {
      setRevisedPrice(priceList?.adults[0]?.adult1)
     
      setAddHandleRate(false);
    }
    
  }

  const getCorpLocationCityList = useSelector((state) => state.corpListing.locationCityList);

  const pageId = useSelector((state) => state.corpListing.corpPageId);
  const dropDownFilterSecId = useSelector((state) => state.corpListing?.corpDropDownFilteredSecId);
  const addImage = useSelector((state) => state.corpListing.allImagesData);
  const selectedMenuDropdown = useSelector((state) => state.corpListing?.selectedDropdown);
  const imageThubnail = addImage.find((img) => img.thumbnail === true);

  const dispatch = useDispatch();
  const imagesWithoutId = addImage;
  const editor = useRef(null);
  let CityAndCountryLabel = `${dropdownValue?.name === "International*" ? "Select Country*" : "City of Location*"} `
  const handleClickSelect = (event) => {
    dispatch(corpListingAction.selectDropdown());
    setSelect(event.currentTarget);
  };

  const handleCloseSelect = (item) => {
    setDropdownValue(item);
    setSelect(null);
    if (item?.gift_voucher === true) {
      setIsGiftVoucher(true);
    } else {
      setIsGiftVoucher(false);
    }
    setIsCorpAddSecnameErrorValidation(false) 
  };

  const [state, setState] = useState({
    package_type: "staybased",
    pack_title: "",
    brief_description: "",
    edenred_product_code:"",
    note: null,
    images: [],
    location: [],
    disable_tomorrows_bookings:false,
    restricted_booking_dates:null,
    schedule_type: "",
    schedule_data: {},
    valid_till: "",
    location_city: "",
    points: 0,
    best_seller: false,
    description: "",
    covid_safe: false,
    max_points: 0,
    min_points: 0,
    featured: false,
    flexi_schedule: false,
    enable_payment: false,
    opening_soon: null,
    location_country: "",
    revised_price: "",
    location_type: "",
    group_size: {min: "", max: ""},
    ages: "",
    activity_type: "",
    activity_level: "",
    itinerary: "",
    accomodations: "",
    essential_info: "",
    faq: "",
    featured_amenities: JSON.parse(JSON.stringify(FEATURED_AMENITIES_LIST)),
    room_amenities: JSON.parse(JSON.stringify(ROOM_AMENITIES_LIST)),
    hotel_resort_facilities: JSON.parse(JSON.stringify(RESORT_FACILITIES_LIST))
  });

  const handleChange = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setIsError({ error:false, msg:'', name: "" })
    setIsCorpAddPackTitleEmptyErrorValidation(false)
    setIsCorpAddPackTitleLengthErrorValidation(false)
  };
  const priceHandler = (key, e) => {
    setState(() => ({
      ...state,
      [key]: e.target.value,
    }));
    setIsError({ error:false, msg:'', name: "" })
    setIsCorpAddPointsEmptyErrorValidation(false)
    setIsCorpAddPointsLengthErrorValidation(false)
  };


  const handleBlockTommorow=(event)=>{
  
    if (event.target.checked === true) {
   
   setState({
    ...state,
    disable_tomorrows_bookings: true
   })
  }
else{

 setState({
  ...state,
  disable_tomorrows_bookings: false
 })

}
  }
  const briefDescriptionHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setIscorpAddBrigDescEmtyErrorValidation(false)
    setIscorpAddBrigDescLengthErrorValidation(false)
  };

  const handleError = (errorMsg) => {
    setIsFeaturedErrorValidation(errorMsg);
  } 

  const validTillHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
    setIscorpAddValidTillErrorValidation(false)
  };
  const loader = (id) => {
    dispatch(corpListingAction.getCorpListing(pageId, dropDownFilterSecId?.id));
    dispatch(corpListingAction.editCorpListItem());
    setIsLoading(false)
  };
  const dateSheduleHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };

  const location = [
    {
      address: "12th A Main Rd",
      latitude: "12.96973150000000",
      longitude: "77.63991412753906",
      city: "Bengaluru",
      state: "Karnataka",
      location: "SRID=4326;POINT (77.63991412753906 12.9697315)",
      active: true,
      created: "2021-08-24T15:41:28.706508",
      location: "SRID=4326;POINT (77.63776836032714 12.9697315)",
      modified: "2021-08-24T15:41:28.706524",
    }
  ];
  function checkSentence(title) {
    const allowedRegex = /[@#*()\-\_,]/;
    const notAllowedRegex = /[~`!$%^&+=\{\}\[\]|\\/:;"‘<>.?]/;

    for (let i = 0; i < title.length; i++) {
        const char = title.charAt(i);
     if (notAllowedRegex.test(char)) {
      setSymbolValidation(true)
      setSymbolErrorMsg(`${char} -> now allowed in title please remove !`)
    
      enqueueSnackbar(`${char} -> now allowed in title please remove !`, {
        variant: "error",
    });

           return 
        } 
        else{
          setSymbolValidation(false)
          setSymbolErrorMsg(false)

        }
    }
}
  const newDataSubmithandler = (e) => {
    console.log(e);
    e.preventDefault();
    checkSentence(state.pack_title)
    if(symbolValidation){ 
    
                              setSymbolValidation(true)
                              return
   }
    console.log('testing gv');
    setIsCorpAddSecnameErrorValidation(false);
    setIsCorpAddPackTitleEmptyErrorValidation(false);
    setIsCorpAddPackTitleLengthErrorValidation(false);
    setIsCorpAddImgErrorValidation(false);
    setIsCorpAddImgThumbnailErrorValidation(false);
    setIsCorpAddPointsEmptyErrorValidation(false);
    setIsCorpAddPointsLengthErrorValidation(false);
    setIscorpAddBrigDescEmtyErrorValidation(false);
    setIscorpAddBrigDescLengthErrorValidation(false);
    setIscoprAddLocationEmptyErrorvalidation(false);
    setIscoprAddLocationLengthErrorvalidation(false);
    setIscorpAddScheduleErrorValidation(false);
    setIscorpAddValidTillErrorValidation(false);
    setIscorpAddDesErrorValidation(false);
    setGiftVoucherMinPointsEmptyError(false);
    setGiftVoucherMaxPointsEmptyError(false);
    setGiftVoucherMaxLengthExceedError(false);
    setGiftVoucherMinLengthExceedError(false);
    setGiftVoucherMinValLess500Error(false);
    setGiftVoucherMaxAvgValError(false);
    setGiftVoucherMaxPointsMod500Error(false);
    setGiftVoucherMinPointsMod500Error(false);
    setCountryError(false)
    let finalImages = addImage.map((item, index) => {

      let sImg = {
        image: item.image,
        video: item.video,
        thumbnail: item.thumbnail,
        priority: index + 1,
        media_type: item.media_type
      };

      return sImg;

    });
    function convertValuesToString(obj) {
      if (obj.days) obj.days = String(obj.days);
      if (obj.nights) obj.nights = String(obj.nights);
      return obj;
  }
    let addNewData = {
      section: dropdownValue?.id,
      package_type: state.package_type,
      pack_title: state.pack_title.trim().replace(/\s+/g, ' '),
      brief_description: state.brief_description,
      edenred_product_code:state.edenred_product_code,
      disable_tomorrows_bookings:state?.disable_tomorrows_bookings,
      restricted_booking_dates:state?.restricted_booking_dates,
      note: state.note,
      images: finalImages,
      location: isGiftVoucher || dropdownValue?.name === "International" ? location : state.location,
      schedule_type: isGiftVoucher ? null : state.schedule_type,
      schedule_data: isGiftVoucher ? {} : convertValuesToString(state.schedule_data),
      valid_till: state.valid_till,
      points: parseInt(priceList?.adults[0]?.adult1),
      best_seller: state.best_seller,
      covid_safe: isGiftVoucher ? false : state.covid_safe,
      description: content,
      max_points: state?.max_points,
      featured: state.featured,
      min_points: state?.min_points,
      location_city: state.location_city,
      flexi_schedule: state.flexi_schedule,
      enable_payment: state.enable_payment,
      opening_soon: state.opening_soon,
      location_country: state.location_country,
      // revised_price: state.revised_price ? state.revised_price : 0,
      location_type: state.location_type,
      group_size: state.group_size,
      ages: state.ages,
      activity_type: state.activity_type,
      activity_level: state.activity_level,
      itinerary: state.itinerary,
      accomodations: state.accomodations,
      essential_info: state.essential_info,
      faq: state.faq,
      featured_amenities: state.featured_amenities,
      room_amenities: state.room_amenities,
      hotel_resort_facilities: state.hotel_resort_facilities,
      price_list:[
        ...priceList?.adults?.map((elem,index)=>{
          return { "age_group":"adult", "num_people":parseInt(`${index+1}`),"price":elem['adult' + (index + 1)]}
        }),
        ...priceList?.children?.map((elem,index)=>{
          return { "age_group":"child", "num_people":parseInt(`${index+1}`),"price":elem['child' + (index + 1)]}
        }),
        // {"age_group":"adult", "num_people":1, "price": parseInt(priceList?.adults?.adult1)}, {"age_group":"adult", "num_people":2, "price": parseInt(priceList?.adults?.adult2)},
        // {"age_group":"adult", "num_people":3, "price": parseInt(priceList?.adults?.adult3)}, {"age_group":"adult", "num_people":4, "price": parseInt(priceList?.adults?.adult4)},
        // {"age_group":"adult", "num_people":5, "price": parseInt(priceList?.adults?.adult5)}, {"age_group":"adult", "num_people":6, "price": parseInt(priceList?.adults?.adult6)},
        // {"age_group":"adult", "num_people":7, "price": parseInt(priceList?.adults?.adult7)}, {"age_group":"adult", "num_people":8, "price": parseInt(priceList?.adults?.adult8)},
        // {"age_group":"adult", "num_people":9, "price": parseInt(priceList?.adults?.adult9)}, {"age_group":"adult", "num_people":10, "price": parseInt(priceList?.adults?.adult10)},
        // {"age_group":"child", "num_people":1, "price": parseInt(priceList?.children?.child1)}, {"age_group":"child", "num_people":2, "price": parseInt(priceList?.children?.child2)},
        // {"age_group":"child", "num_people":3, "price": parseInt(priceList?.children?.child3)}, {"age_group":"child", "num_people":4, "price": parseInt(priceList?.children?.child4)},
        // {"age_group":"child", "num_people":5, "price": parseInt(priceList?.children?.child5)}, {"age_group":"child", "num_people":6, "price": parseInt(priceList?.children?.child6)},
        // {"age_group":"child", "num_people":7, "price": parseInt(priceList?.children?.child7)}, {"age_group":"child", "num_people":8, "price": parseInt(priceList?.children?.child8)},
        // {"age_group":"child", "num_people":9, "price": parseInt(priceList?.children?.child9)}, {"age_group":"child", "num_people":10, "price": parseInt(priceList?.children?.child10)}
    ]
    };
    const htmltoText = (html) => {
      let text = html;
      text = text.replace(/\n/gi, "");
      text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
      text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
      text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, "");
      text = text.replace(/<\/div>/gi, "");
      text = text.replace(/<\/li>/gi, "");
      text = text.replace(/<li.*?>/gi, "  *  ");
      text = text.replace(/<\/ul>/gi, "");
      text = text.replace(/<\/p>/gi, "");
      text = text.replace(/<br\s*[\/]?>/gi, "");
      text = text.replace(/<[^>]+>/gi, "");
      text = text.replace(/^\s*/gim, "");
      text = text.replace(/ ,/gi, "");
      text = text.replace(/ +/gi, "");
      text = text.replace(/\n+/gi, "");
      text = text.replace('&nbsp;', "");
      return text;
    };
  if(htmltoText(content).replaceAll(' ', '').trim().length===0){
    setIscorpAddDesErrorValidation(true);
    focusInput("#desc")
      return;
  }
    if (addNewData.flexi_schedule) {
      addNewData.schedule_type = '';
    }
    console.log('testing gv');
    if (dropdownValue?.id === undefined || dropdownValue?.id === null) setIsCorpAddSecnameErrorValidation(true)
    else if (state.pack_title === "" || state.pack_title === undefined) setIsCorpAddPackTitleEmptyErrorValidation(true)
    else if (state.pack_title.length > 75) setIsCorpAddPackTitleLengthErrorValidation(true)
    else if (addImage.length === 0) setIsCorpAddImgErrorValidation(true)
    else if (!imageThubnail) setIsCorpAddImgThumbnailErrorValidation(true)
    else if (isGiftVoucher === true && (state.min_points === "" || state.min_points === 0)) setGiftVoucherMinPointsEmptyError(true);
    else if (isGiftVoucher === true && (state.max_points === "" || state.max_points === 0)) setGiftVoucherMaxPointsEmptyError(true);
    else if (isGiftVoucher === true && (state.min_points % 500 !== 0)) setGiftVoucherMinPointsMod500Error(true);
    else if (isGiftVoucher === true && (state.max_points % 500 !== 0)) setGiftVoucherMaxPointsMod500Error(true);
    else if (isGiftVoucher === true && (parseInt(state.min_points) > parseInt(state.max_points))) {
      setGiftVoucherMaxAvgValError(true);
    }
    else if(Object.values(priceList).every((category) =>
    Object.values(category).every((value) => value === null))){
      enqueueSnackbar("Please fill all the fields of add rate", {
        variant: "error",
    });
  }
    else if (isGiftVoucher === true && (state.min_points.length > 6)) setGiftVoucherMinLengthExceedError(true);
    else if (isGiftVoucher === true && (state.max_points.length > 6)) setGiftVoucherMaxLengthExceedError(true);
    else if (isGiftVoucher === true && (parseInt(state.min_points) < 500)) setGiftVoucherMinValLess500Error(true);
    // else if (!isGiftVoucher && (state.points === "" || state.points === 0)) setIsCorpAddPointsEmptyErrorValidation(true)
    else if (!isGiftVoucher && state.points.length > 6) setIsCorpAddPointsLengthErrorValidation(true)
    else if (priceList?.adults[0]?.adult1.length > 6) {
      setIsError({error: true, name: "revised_price", msg: REVISED_PRICE_LENGTH})
      focusInput("#price")
    }
    else if(Number(state.price) <= Number(priceList?.adults[0]?.adult1)) {
      setIsError({ error:true, msg:REVISED_PRICE_ERROR, name: "revised_price" })
      focusInput("#price")
    }
    else if (state.brief_description === "" || state.brief_description === undefined) {
      setIscorpAddBrigDescEmtyErrorValidation(true)
      focusInput("#brief")
    }
    else if (state.brief_description.length > 300) {
      setIscorpAddBrigDescLengthErrorValidation(true)
      focusInput("#brief")
    }
    else if (!isGiftVoucher && ((state.group_size.min) > Number(state.group_size.max)) ) {
      setIsError({error: true, name: "guest_no", msg: GUEST_ERROR})
      focusInput("#groupSize")
    }
    else if (dropdownValue?.name === "International" && state?.location_country === "") {
      setIsError({error: true, name: "location_error", msg: COUNTRY_ERROR});
      focusInput("#locationDropdown")
    }
    else if (dropdownValue?.name !== "International" && state?.location_city === "") {
      setIsError({error: true, name: "location_error", msg: CITY_ERROR});
      focusInput("#locationDropdown")
    }
    else if (dropdownValue?.name !== "International" && (state.location === undefined || state.location.length === 0)) setIscoprAddLocationEmptyErrorvalidation(true)
    else if (dropdownValue?.name !== "International" && state.location.length > 300) setIscoprAddLocationLengthErrorvalidation(true)

    // else if (!isGiftVoucher && Object.entries(state.schedule_data).length === 0) setIscorpAddScheduleErrorValidation(true)
    // else if ((state.schedule_data === {} || state.schedule_data.days === undefined && state.schedule_data.nights === undefined) && !state.flexi_schedule && !isGiftVoucher) 
    else if (
      !isGiftVoucher &&
      !state.flexi_schedule &&
      (!scheduledData || Object.entries(scheduledData).length === 0)
    ){
      setIscorpAddScheduleErrorValidation(true)
      focusInput(state.package_type == "itinerary" ? "#desc" :"#schedule")
    }

    else if (state.valid_till === "" || state.valid_till === undefined) {
      setIscorpAddValidTillErrorValidation(true)
      focusInput("#validTill")
    }
    else if (state.location_type == "" && state.package_type == "staybased") {
      setIsError({error: true, name: "location_type", msg: LOCATION_TYPE_EMPTY})
      focusInput("#locationType")
    }
    else if (state.location_type?.length > 20 && state.package_type == "staybased") {
      setIsError({error: true, name: "location_type", msg: LOCATION_TYPE_CHAR_LIMIT})
      focusInput("#locationType")
    }
    else if (state.activity_type == "" && state.package_type == "itinerary") {
      setIsError({error: true, name: "activity_type", msg: ACTIVITY_TYPE_EMPTY})
      focusInput("#itineraryFields")
    }
    else if (state.activity_level == "" && state.package_type == "itinerary") {
      setIsError({error: true, name: "activity_level", msg: ACTIVITY_LEVEL_EMPTY})
      focusInput("#itineraryFields")
    }
    else if (state.ages == "" && state.package_type == "itinerary") {
      setIsError({error: true, name: "ages", msg: AGES_EMPTY})
      focusInput("#itineraryFields")
    }
    else if (checkFeaturedAmenitiesLength(state.featured_amenities) && state.package_type == "staybased") {
      setIsError({error: true, name: "featured_amenities", msg: FEATURED_AMENITIES_ERROR})
      focusInput("#amenities")
    }
    else if (content === "" || content === undefined) setIscorpAddDesErrorValidation(true)
    else {
      setIsLoading(true);
      dispatch(corpListingAction.handleAddNewData(addNewData, refreshData, handleError, loader));
    }
  };

  const saveDateDataBlockDates = (date) => {
    if(date==null){
    
    
        setState({
          ...state,
          restricted_booking_dates:null
         })

    }
 
    else if(date){
    
    
        setState({
          ...state,
          restricted_booking_dates:{
            "selected_dates":date
          }
         })

    }
  
       setPopupDateBLock(false)


  };

  const refreshData = () => {
    setState({
      pack_title: "",
      brief_description: "",
      note: null,
      images: [],
      location: [],
      schedule_type: "",
      schedule_data: {},
      valid_till: "",
      // featured: false,
      points: "",
      best_seller: false,
      location_city: "",
      description: "",
      location_country: "",
    });
    enqueueSnackbar("Package added successfully", {
      variant: "success",
    });
    setIsLoading(false);
    window.location.reload();
  };

  // all options from https://xdsoft.net/jodit/doc/
  const configJoditEditor = {
    readonly: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    placeholder: "Description...",
    addNewLineOnDBLClick: false,
    controls: {
      font: false,
    },
    height: "500px"
  };

  const setSchedules = (days, nights, schedule_p) => {
    setState((prevstate) => {
      return { 
        ...prevstate, 
        schedule_data: { days: days, nights: nights } ,
        schedule_type: schedule_p,
      };
    });
    setPopupSelect(false);
    setScheduleType(schedule_p);
    setIscorpAddScheduleErrorValidation(false)
  };
  const formattedDays = (array) => {
    let weekDays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return array
      .map((item) => {
        return weekDays[item];
      })
      .join();
  };

  const setRecurring = (selectedDays, hours, minutes, schedule_t) => {
    // dispatch(experiencesListingAction.setSelectedDay([]));
    var newSelectedDyas = [];
    for (var i = 0; i < selectedDays.length; i++) {
      if (selectedDays[i] === "Sunday") {
        newSelectedDyas.push(0);
      } else if (selectedDays[i] === "Monday") {
        newSelectedDyas.push(1);
      } else if (selectedDays[i] === "Tuesday") {
        newSelectedDyas.push(2);
      } else if (selectedDays[i] === "Wednesday") {
        newSelectedDyas.push(3);
      } else if (selectedDays[i] === "Thursday") {
        newSelectedDyas.push(4);
      } else if (selectedDays[i] === "Friday") {
        newSelectedDyas.push(5);
      } else if (selectedDays[i] === "Saturday") {
        newSelectedDyas.push(6);
      }
    }
    setState((prevstate) => {
      return {
        ...prevstate,
        schedule_data: {
          newSelectedDyas: newSelectedDyas,
          recurringHours: hours,
          recurringMinutes: minutes,
        },
        schedule_type: schedule_t,
      };
    });
    setPopupSelect(false);
    setScheduleType(schedule_t);
    console.log("selectedDaysState", state);
  };

  const setSpecificDate = (multipleDates, hours, minutes, schedule_d) => {
    setState((prevstate) => {
      return {
        ...prevstate,
        schedule_data: {
          multipleDates: multipleDates,
          hours: hours,
          minutes: minutes,
        },
        schedule_type: schedule_d,
      };
    });
    setPopupSelect(false);
    setScheduleType(schedule_d);
    console.log("daterr", state.schedule_type);
  };

  const setDateRanges = (selectedDates, schedule_r) => {
    // selectedDates.forEach(function (arrayItem) {
    //   arrayItem.from = moment(arrayItem.from).format("DD-MM-YYYY");
    //   arrayItem.to = moment(arrayItem.to).format("DD-MM-YYYY");
    // });
    setState((prevstate) => {
      return {
        ...prevstate,
        schedule_data: { selectedDates: selectedDates },
        schedule_type: schedule_r,
      };
    });
    selectedDates.map((item, index) => {
      if(selectedDates.length == index + 1 ){
        setState(() => ({
          ...state,
          schedule_data: { selectedDates: selectedDates },
          schedule_type: schedule_r,
          "valid_till": moment(item.to, "DD-MM-YYYY").format("yyyy-MM-DD"),
        }));
      }
    })
    setPopupSelect(false);
    setScheduleType(schedule_r);
    setIscorpAddScheduleErrorValidation(false)

  };

  const handleAddRate=()=>{
    setAddHandleRate(true)
  }
  const handleCloseImage=()=>{
    setImageSelect(false)
    dispatch(corpListingAction.handleAllImages([]))
  }

  const setLocation = (address, latitude, longitude, city, state, country) => {
    setState((prevstate) => {
      return {
        ...prevstate,
        location: [
          {
            address: address,
            latitude: latitude,
            longitude: longitude,
            city: city,
            state: state,
            country: country
          },
        ],
        location_country: country,
      };
    });
    setIscoprAddLocationEmptyErrorvalidation(false)
    setIscoprAddLocationLengthErrorvalidation(false)
  };

  const handleEnablePayment = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleFlexiSchedule = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
      schedule_data: event.target.checked ? {} : state?.schedule_data,
      schedule_type: event.target.checked ? '' : state?.schedule_type
    });
    setIscorpAddScheduleErrorValidation(false)
  };
  const handleFeaturedpackage = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  const noteHandler = (key, value) => {
    setState(() => ({
      ...state,
      [key]: value.target.value,
    }));
  };
  const handleBsetSeller = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const covidSafeHandler = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleUpdatePackageImage = (data) => {
    setState({
      ...state,
      images: data,
    });
    setIsCorpAddImgErrorValidation(false)
    setIsCorpAddImgThumbnailErrorValidation(false)
  };

  if (state.schedule_type === "package" || scheduleType === "package") {
    scheduledData =
      scheduleDaysVal(state?.schedule_data?.days) +
      scheduleNightsVal(state?.schedule_data?.nights);
  } else if (
    state?.schedule_type === "date_range" ||
    scheduleType === "date_range"
  ) {
    scheduledData =
      state?.schedule_data.selectedDates &&
      state?.schedule_data.selectedDates !== null &&
      state?.schedule_data.selectedDates.map(
        (item) => `${item.from} to ${item.to}`
      );
  } else if (
    state?.schedule_type === "recurring" ||
    scheduleType === "recurring"
  ) {
    scheduledData =
      state?.schedule_data.newSelectedDyas &&
      state?.schedule_data.newSelectedDyas !== null &&
      formattedDays(state?.schedule_data?.newSelectedDyas) +
        " " +
        scheduleHoursVal(state?.schedule_data?.recurringHours) +
        scheduleMinutesVal(state?.schedule_data?.recurringMinutes);
  } else if (
    state?.schedule_type === "specific_dates" ||
    scheduleType === "specific_dates"
  ) {
    scheduledData =
      state?.schedule_data.multipleDates &&
      state?.schedule_data.multipleDates !== null &&
      state?.schedule_data.multipleDates.map(
        (item) => `${moment(item).format("Do MMMM")}`
      ) +
        scheduleHoursVal(state?.schedule_data?.hours) +
        scheduleMinutesVal(state?.schedule_data?.minutes);
  }

  const postLocationCityData = (data) => {
    if (dropdownValue?.name === "International") {
      setState({
        ...state,
        location_country: data
      })
    } else {
    setState({
      ...state,
      location_city: data
    })
    setIsError({error: false, name: "", msg: ""})
  }
  };
  const openingSoonLabel = () => {
    if (state.opening_soon !== "" && state.opening_soon !== null) return `Opening on ${state.opening_soon}`
    else {
      return "Opening Soon?"
    }
  };
  const openingSoonhandler = (event) => {
    if (event.target.checked === false) {
      setState({
        ...state,
        opening_soon: "",
      });
    } else if (state.opening_soon) return event.target.checked
    else { return event.preventDefault(), setOpeningSoon(true) }
  };
  const dateChange = (date) => {
    setState({
      ...state,
      opening_soon: (date.format('YYYY-MM-DD')),
    });
    setOpeningSoon(false)
  };

  useEffect(()=>{
    const datesArray=state?.restricted_booking_dates?.selected_dates
       
         if(datesArray){
          const data=datesArray[0]?datesArray[0]:false
          const data1=datesArray[1]?datesArray[1]:false
          setBlock_date_range(`${data ? `${data.from} to ${data.to}` : ''}${data1 ? `,${data1.from}` : ''}`);
    
        }

  },[state])

  useEffect(()=>{
    dispatch(bookingAction.selectBlockDatesRestrictions(state))

  },[state])

  return (
    <div>
      {isLoading && <div style={{ marginLeft: 10, marginBottom: -3 }}><LinearProgress /></div>}
      <div className="packageDetails-holder">
        <form onSubmit={newDataSubmithandler}>
          <div className="form-options">
            <div>
              <h2>Adding</h2>
            </div>
            <div className="form-btns d-flex align-items-center">
            <div style={{}}>
                  <FeaturedPackage featured={state.featured} handleFeaturedpackage={handleFeaturedpackage}/>
                </div>
              <div
                className="section-dropdown"
                style={{
                  position: "relative",
                  cursor: "pointer",
                  width: "auto",
                }}
              >
                <div>
                  <Button
                    style={{ paddingRight: "32px" }}
                    aria-controls="section-menu"
                    aria-haspopup="true"
                    onClick={handleClickSelect}
                  >
                    {dropdownValue?.name || "Select section"}
                    <img
                      alt="arrow"
                      src={ArrowDown}
                      style={{
                        position: "absolute",
                        right: "12px",
                        top: "12px",
                      }}
                    />
                  </Button>
                  <Menu
                    id="section-menu"
                    anchorEl={select}
                    keepMounted
                    open={Boolean(select)}
                    onClose={() => setSelect(null)}
                  >
                    {selectedMenuDropdown &&
                      selectedMenuDropdown.map((item) => {
                        return (
                          <MenuItem
                            onClick={() => handleCloseSelect(item)}
                            key={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Menu>
                </div>
              </div>
              <div>
                <button type="submit" disabled={isLoading} className="btn btn-primary btn-save">
                  Save
                </button>
              </div>
            </div>
          </div>
          {isCorpAddSecnameErrorValidation && <p style={{ textAlign: "end", marginRight: "9%", color: "red" }}>{SECTION_NAME}</p>}
          {isFeaturedErrorValidation && <p style={{ textAlign: "end", marginRight: "9%", color: "red" }}>{isFeaturedErrorValidation}</p>}
          <PackTypeButtons fromAdd={true} state={state} setState={setState} label={true}/>
          <div>
            <div className="form-group form-custom">
              <label for=""> Listing Title *</label>
              <input
                value={state?.pack_title}
                type="text"
                className="form-control"
                onChange={(value) => handleChange("pack_title", value)}
              />
              {isCorpAddPackTitleEmptyErrorValidation ?
                <p style={{ color: "red" }}>{PACKAGE_TITLE_EMPTY_ERROR_VALIDATION}</p> :
                isCorpAddPackTitleLengthErrorValidation ?
                  <p style={{ color: "red" }}>{PACKAGE_TITLE_LENGTH_ERROR_VALIDATION}</p>
                  : ""}
                    {symbolValidation?<Error error={symbolErrorMsg}/>:""}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <div
                  onClick={() => setImageSelect(true)}
                  style={{
                    cursor: "pointer",
                    position: "relative",
                    width: 320,
                    height: 200,
                  }}
                >
                  <img
                    alt=""
                    src={imageThubnail ? imageThubnail.image : DefaultImage}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: 14,
                      backgroundColor: "#CECECE",
                    }}
                  />
                  <h6
                    style={{
                      color: "white",
                      position: "absolute",
                      bottom: 20,
                      // left: 100,
                      left: !isGiftVoucher ? 80 : 100,
                      border: "1px solid white",
                      padding: 6,
                      borderRadius: 6,
                      backgroundColor: "#525252",
                    }}
                  >
                    <span style={{ padding: "0px 5px" }}>
                      <FaFileUpload />
                    </span>
                    {addImage.length > 0 ? addImage.length : 0}
                    {/* images */}
                    {!isGiftVoucher ? ' images / videos' : 'images'}
                  </h6>
                  {isCorpAddImgErrorValidation ? <p style={{ color: "red" }}>{IMAGE}</p> : isCorpAddImgThumbnailErrorValidation ? <p style={{ color: "red" }}>{THUMBNAIL_IMAGE_ERROR}</p> : ""}
                </div>

                <div className="form-group form-custom">
                  <div className="package-price" id="price">
                    <div className="form-group form-custom" style={{
        display: 'flex',
        background: 'white',
        justifyContent: 'space-between',
        width: '89%',
        height: '41px',
        marginLeft: '14px',
        marginBottom: '0px',
      }}>
                      <p style={{opacity:"0.5"}}>Add Rate *</p> 
                      <img src={PlusIcon} alt="addIcon" style={{cursor:"pointer"}}  onClick={handleAddRate}/>
                      </div>
                  </div>
                <div
                  style={{ flex: 0.5, position: "relative", marginTop: "22px" }}
                  className="form-group form-custom"
                >

                  
                  <label for="">{`${!isGiftVoucher ? "Price*" : "Amount range"}`}</label>
                  <h6
                    style={{
                      position: "absolute",
                      left: "30px",
                      top: "53px",
                      fontWeight: "400",
                      opacity: 0.4,
                    }}
                  >
                    Pts
                  </h6>
                  <div
                    style={{
                      position: "absolute",
                      paddingBottom: '36px',
                      height: "17%",
                      width: "1px",
                      left: "65px",
                      backgroundColor: "rgb(202 194 194)",
                    }}
                  />
                  {!isGiftVoucher ?
                    <div style={{ display: "flex" }} id="price">
                      <input
                        style={{ paddingLeft: "75px", width: `${isGiftVoucher ? "150px" : ""}` }}
                        value={`${state?.points === 0 ? revisePrice: revisePrice}`}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        type="text"
                        className="form-control"
                        disabled={true}
                        // onChange={(value) => priceHandler("points", value)}
                      />
                    </div> : null}
                  {isGiftVoucher ?
                    <div style={{ display: "flex" }}>
                      <input
                        style={{ paddingLeft: "60px", width: `${isGiftVoucher ? "150px" : ""}` }}
                        value={`${state?.min_points === 0 ? "" : state?.min_points}`}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        type="text"
                        placeholder="Min-Point"
                        className="form-control"
                        onChange={(value) => priceHandler("min_points", value)}
                      />
                      {isGiftVoucher &&
                        <>
                          <span style={{ padding: "6px" }}>-</span>
                          <input
                            style={{ paddingLeft: '15px', width: "120px" }}
                            value={state?.max_points > 0 ? state?.max_points : ""}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            type="text"
                            className="form-control"
                            placeholder="Max-Point"
                            onChange={(value) => priceHandler("max_points", value)}
                          />
                        </>
                      }
                    </div> : null}
                  {giftVoucherMinPointsEmptyError ? <p style={{ color: "red" }}>Please add min points</p> : null}
                  {giftVoucherMaxPointsEmptyError ? <p style={{ color: "red" }}>Please add max points</p> : null}
                  {giftVoucherMinPointsMod500Error ? <p style={{ color: "red" }}>Min value should be a multiple of 500</p> : null}
                  {giftVoucherMaxPointsMod500Error ? <p style={{ color: "red" }}>Max value should be a multiple of 500</p> : null}
                  {giftVoucherMaxAvgValError ? <p style={{ color: "red" }}>Max Points should not be less than min points</p> : null}
                  {giftVoucherMinLengthExceedError ? <p style={{ color: "red" }}>Min Points should not be greater than 6 digits</p> : null}
                  {giftVoucherMaxLengthExceedError ? <p style={{ color: "red" }}>Max Points should not be greater than 6 digits</p> : null}
                  {giftVoucherMinValLess500Error ? <p style={{ color: "red" }}>Min Points should not be less than 500</p> : null}
                  {isCorpAddPointsEmptyErrorValidation ?
                    <p style={{ color: "red" }}>{POINTS_EMPTY}</p> :
                    isCorpAddPointsLengthErrorValidation ?
                      <p style={{ color: "red" }}>{POINTS_EMPTY_LENGTH}</p> :
                      ""
                  }
                </div>
                </div>
                {/* {!isGiftVoucher &&<div className="package-price">
                  <div className="form-group form-custom">
                    <h6>Pts</h6>
                    <Input
                      label="Revised Price*"
                      value={state?.revised_price}
                      handleChange={(value) => priceHandler("revised_price", value)}
                      isDivider={true}
                      disableChar={true}
                    />
                    {isError.error && isError.name == "revised_price" ? <Error error={isError.msg} /> : ""}
                  </div>
                </div>} */}
                <div
                  style={{ height: "164px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{alignItems:"flex-end"}}>
                  <label for="" style={{ marginLeft:"17px"}}>Note</label>
                  <p style={{ marginLeft:"17px", fontSize:"12px", fontWeight:"400", lineHeight:"14px", opacity:"0.4",color:"#000000"}}>{state?.note != null ? state?.note.trim().length : 0}/100</p>
                  </div>
                  <textarea
                   style={{ height: "98px",resize: "none" }}
                    value={state?.note}
                    maxlength="100"
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      noteHandler("note", value)
                    }
                  />
                </div>
                <div
                  style={{ height: "100px" }}
                  className="form-group form-custom"
                >
                  <div className="row" style={{ alignItems: "flex-end" }}>
                    <label for="" style={{ marginLeft: "17px" }}>Product Code</label>
                    <p style={{ marginLeft: "17px", fontSize: "12px", fontWeight: "400", lineHeight: "14px", opacity: "0.4", color: "#000000" }}>{state?.edenred_product_code.trim().length || 0}/30</p>
                  </div>
                  <textarea
                    style={{ height: "40px",resize: "none" }}
                    resize= "none"
                    maxlength="30"
                    value={state.edenred_product_code}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                     noteHandler("edenred_product_code", value)
                    }
                  />
                </div>
                <div style={{ width: "100%", height: 'auto', marginBottom: "1px" }} className="form-group form-custom" id="brief">
                  <label for="">Brief Description *</label>
                  <textarea
                  // style={{ height: '78%' }}
                  rows="6"
                    value={state?.brief_description}
                    type="text"
                    className="form-control"
                    onChange={(value) =>
                      briefDescriptionHandler("brief_description", value)
                    }
                  />
                  {iscorpAddBrigDescEmtyErrorValidation ?
                    <p style={{ color: "red" }}>{BRIEF_DESCRIPTION_EMPTY}</p> :
                    iscorpAddBrigDescLengthErrorValidation ?
                      <p style={{ color: "red" }}>{BRIEF_DESCRIPTION_EMPTY_LENGTH}</p>
                      : ""}
                </div>
                {!isGiftVoucher && state.package_type == "staybased" && 
                (<div className="form-group form-custom" id="groupSize">
                  <label for="">Guests No’s</label>
                  <MinMaxInput 
                    state={state} 
                    disableChar={true} 
                    label="Guests No."
                    placeholder1="Min"
                    placeholder2="Max"
                    setState={setState}
                    setIsError={setIsError}
                  />
                  {isError.error && isError.name == "guest_no" ? <Error error={isError.msg} /> : ""}
                </div>)}
                {!isGiftVoucher && state.package_type == "staybased" &&
                (<div className="toggle-container">
                  <ToggleGroup
                    setState={setState}
                    state={state}
                    options={state.hotel_resort_facilities}
                    label="Hotel/ Resort Facilities"
                    optionsKey="hotel_resort_facilities"
                  />
                </div>)}
                <div className="form-group toggle-container" style={{ marginTop: "10px", marginLeft: "10px"}}>
                    <label for="" style={{ fontSize: "12px", opacity: 0.4, fontWeight: 400 }}>Highlighting</label>
                    <div className="toggle" style={{ backgroundColor: "white", height: "fit-content", borderRadius: "5px", marginTop: "5px" }}>
                      <FormControl component="fieldset">
                        <FormControlLabel
                          value="start"
                          control={
                            <PurpleSwitch
                              checked={state?.best_seller}
                              size="small"
                              color="primary"
                              name="best_seller"
                              onChange={handleBsetSeller}
                            />
                          }
                          label="Bestseller?"
                          labelPlacement="start"
                        />
                      </FormControl>
                    </div>
                    { dropdownValue?.name === "International" ?
                      <div className="toggle" style={{ backgroundColor: "white", height: "fit-content", borderRadius: "5px", marginTop: "5px" }}>
                        <ToggleButton
                          label={openingSoonLabel()}
                          name="opening_soon"
                          checkValue={state?.opening_soon}
                          handleToggleChange={(event) => openingSoonhandler(event)}
                        />
                        <OpeningSoonCalander
                          open={openingSoon}
                          closeHandle={() => setOpeningSoon(false)}
                          dateChange={dateChange}
                        />
                      </div> : null
                    }
                    {!isGiftVoucher &&
                      <div className="toggle" style={{ backgroundColor: "white", height: "fit-content", borderRadius: "5px", marginTop: "5px" }}>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            value="start"
                            control={
                              <PurpleSwitch
                                checked={state?.covid_safe}
                                size="small"
                                color="primary"
                                name="covid_safe"
                                onChange={covidSafeHandler}
                              />
                            }
                            label="Covid safe?"
                            labelPlacement="start"
                          />
                        </FormControl>
                      </div>
                    }
                  </div>
              </div>
              <div style={{ width: "100%" }}>
                {!isGiftVoucher && dropdownValue?.name !== "International" &&
                  <div
                    className="form-group form-custom"
                    style={{ flex: 1, marginLeft: 10 }}
                  >
                    <label for="">{isGiftVoucher ? "Redeem at" : "Location*"}</label>
                    <div className="location-field">
                      <textarea
                        className="form-control"
                        rows="3"
                        cols="40"
                        type="text"
                        value={state?.location[0]?.address}
                        disabled
                      />
                      <div className="pin-icon">
                        <img
                          className="cursor-pointer"
                          src={PinLocation}
                          alt=""
                          onClick={() => setLocationSelect(true)}
                        />
                      </div>
                    </div>
                    {iscoprAddLocationEmptyErrorvalidation ?
                      <p style={{ color: "red" }}>{LOCATION_EMPTY}</p> :
                      iscoprAddLocationLengthErrorvalidation ?
                        <p style={{ color: "red" }}>{LOCATION_EMPTY_LENGTH}</p>
                        : ""}
                    {locationSelect && (
                      <GoogleMapModal
                        edit={isEdit}
                        open={locationSelect}
                        toggleModal={setLocationSelect}
                        handleCloseDone={() => setLocationSelect(false)}
                        updateLocation={setLocation}
                      />
                    )}
                  </div>}
                  <SecondLocation currentPackage={state} setCurrentPackage={setState} isInternational={dropdownValue?.name === "International"}/>
                {!isGiftVoucher &&
                  <div
                    style={{ display: "block", position: "relative", marginLeft: "10px" }}
                    className="form-group form-custom"
                    id="locationDropdown"
                  >
                    <label for=""> {CityAndCountryLabel} </label>
                    <Autocomplete
                      options={dropdownValue?.name === "International" ? countryList : getCorpLocationCityList}
                      city={state?.location_city}
                      locationCityval={dropdownValue?.name === "International" ? state?.location_country : state?.location_city}
                      postLocationCityData={postLocationCityData}
                    />
                    {isError.error && isError.name == "location_error" ? <Error error={isError.msg} /> : ""}
                  </div>}
                  {state.package_type == "staybased" &&
                      (<div className="form-group form-custom" style={{marginLeft: "10px"}} id="locationType">
                        <Input
                          label="Location Type*"
                          value={state?.location_type}
                          handleChange={(val) => handleChange("location_type", val)}
                        />
                        {isError.error && isError.name == "location_type" ? <Error error={isError.msg} /> : ""}
                      </div>)}
                {!isGiftVoucher && state.package_type == "staybased" &&
                  <div
                    style={{ flex: 0.6, marginLeft: 10, position: "relative" }}
                    className="form-group form-custom"
                    id="schedule"
                  >
                    <div className="d-flex" style={{ background: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem 0', lineHeight: '1.5', fontWeight: '400', color: '#495057', fontSize: '1rem', marginBottom: 10 }}>
                      <div className="col-md-9" >Flexible dates</div>
                      <div className="col" style={{ textAlign: 'right' }}>
                        <PurpleSwitch
                          checked={
                            state.flexi_schedule
                          }
                          size="small"
                          color="#2196F3"
                          name="flexi_schedule"
                          onChange={handleFlexiSchedule}
                        />
                      </div>
                    </div>
                    <label for="">Schedule*</label>
                    <input
                      value={!state.flexi_schedule ? scheduledData : ""}
                      type="text"
                      className="form-control"
                      disabled={state.flexi_schedule}
                      onChange={(value) =>
                        dateSheduleHandler("schedule_data", value)
                      }
                    />
                    {iscorpAddScheduleErrorValidation && <p style={{ color: "red" }}>{SCHEDULE}</p>}
                    <img
                      src={Edit}
                      style={{ position: "absolute", right: "20px", top: "105px", padding: "0px 10px", background: "#fff" }}
                      onClick={() => !state.flexi_schedule && setPopupSelect(true)}
                    />
                    {/* {popupSelect && (
                      <Popup
                        openPopup={popupSelect}
                        closeModal={setPopupSelect}
                        updatePopupData={setSchedules}
                        IsCorpAddNew={true}
                      />
                    )} */}
                    
                  </div>}

                                                      {/* blocked_dates */}
                  
                                                      <div
                    style={{ flex: 0.5, marginLeft: 10, position: "relative" }}
                    className="form-group form-custom" id="schedule"
                  >
                    <div className="d-flex" style={{ background: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem 0', lineHeight: '1.5', fontWeight: '400', color: '#495057', fontSize: '1rem', marginBottom: 10 }}>
                      <div className="col-md-9" >Next Day Booking</div>
                      <div className="col" style={{ textAlign: 'right' }}>
                        <PurpleSwitch
                         size="small"
                         checked={state?.disable_tomorrows_bookings}
                          color="#2196F3"
                          name="flexi_schedule"
                          onChange={(event)=>handleBlockTommorow(event)}

                        />
                     
                      </div>
                    </div>
                   
                    <label for="">Block Date Range </label>
                    <input
                      value={block_date_range ? block_date_range:""}
                      disabled={state?.restricted_booking_dates}
                      type="text"
                      className="form-control"
                      id="schedule"
                    />
                  
                    <img
                      src={Edit}
                      style={{ position: "absolute", right: "20px", top: "105px", padding: "0px 10px", }}
                      onClick={() => { setPopupDateBLock(true) }}
                    />
                  </div>

                  {popupDateBlock && (
                  <BlockDatePopup
                    openPopup={popupDateBlock}
                    closeModal={setPopupDateBLock}
                    // updatePopupData={updateDateDataBlockDates}
                    saveDateData={saveDateDataBlockDates}
                    isHolidayListing={true}
                    data={state}
                    popupDateBlock={popupDateBlock}
                    
                  />
                )}
                  {/* blocked_dates */}
                    {popupSelect && (
                        <SchedulePopUp
                          openPopup={popupSelect}
                          closeModal={setPopupSelect}
                          updatePopupData={setSchedules}
                          updateRecurringData={setRecurring}
                          updateScheduledTimeData={setSpecificDate}
                          updateDateRangeData={setDateRanges}
                          isSpecificDisabled={true}
                          isRecurringDisabled={true}
                        />
                      )}
                {!isGiftVoucher && <div
                  style={{ flex: 0.5, position: "relative", marginLeft: "10px" }}
                  className="form-group form-custom"
                >

                  <div className="d-flex" style={{ background: '#fff', border: '1px solid #ced4da', borderRadius: '.25rem', padding: '.375rem 0', lineHeight: '1.5', fontWeight: '400', color: '#495057', fontSize: '1rem' }}>
                    <div className="col-md-9" >Enable Payment</div>
                    <div className="col" style={{ textAlign: 'right' }}>
                      <PurpleSwitch
                        checked={
                          state.enable_payment
                        }
                        size="small"
                        color="#2196F3"
                        name="enable_payment"
                        onChange={handleEnablePayment}
                      />
                    </div>
                    {/* </div> */}
                  </div>
                </div>}
                <div
                  style={{ flex: 0.6, marginLeft: 10 }}
                  className="form-group form-custom"
                  id="validTill"
                >
                  <div>
                    <label for="">Valid till*</label>
                    <input
                      value={state?.valid_till}
                      type="date"
                      min={new Date().toISOString().split('T')[0]}
                      className="form-control"
                      onChange={(value) =>
                        validTillHandler("valid_till", value)
                      }
                      disabled={state?.schedule_type == "date_range"}
                    />
                    {iscorpAddValidTillErrorValidation &&
                      <p style={{ color: "red" }}>{VALID_TILL}</p>
                    }
                  </div>
                  </div>
                  {!isGiftVoucher && state.package_type == "staybased" && 
                  (<div className="toggle-container" style={{marginLeft: "10px"}} id="amenities">
                    <ToggleGroup
                      setState={setState}
                      state={state}
                      options={state.featured_amenities}
                      label="Featured Amenities *"
                      optionsKey="featured_amenities"
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "featured_amenities" ? <Error error={isError.msg} /> : ""}
                  </div>)}
                  {!isGiftVoucher && state.package_type == "staybased" &&
                  (<div className="toggle-container" style={{marginLeft: "10px"}}>
                    <ToggleGroup
                      setState={setState}
                      state={state}
                      options={state.room_amenities}
                      label="Room Amenities"
                      optionsKey="room_amenities"
                    />
                  </div>)}
                  {!isGiftVoucher && state.package_type == "itinerary" && 
                (<div className="form-group form-custom" style={{marginLeft: "10px"}} id="groupSize">
                  <label for="">Group Size*</label>
                  <MinMaxInput 
                    state={state} 
                    disableChar={true} 
                    label="Group Size"
                    placeholder1="Min"
                    placeholder2="Max"
                    setState={setState}
                    setIsError={setIsError}
                  />
                  {isError.error && isError.name == "guest_no" ? <Error error={isError.msg} /> : ""}
                </div>)}
                  {!isGiftVoucher && state.package_type == "itinerary" &&
                  (<div className="form-group form-custom" style={{marginLeft: "10px"}} id="itineraryFields">
                    <label>Activity Type*</label>
                    <Dropdown 
                      options={ACTIVITY_OPTIONS}
                      setState={setState}
                      state={state}
                      label="activity_type"
                      value={state?.activity_type}
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "activity_type" ? <Error error={isError.msg} /> : ""}
                    <label className="mt-3">Activity Level*</label>
                    <Dropdown 
                      options={ACTIVITY_LEVEL_OPTIONS}
                      setState={setState}
                      state={state}
                      label="activity_level"
                      value={ACTIVITY_LEVEL_VALUE(state?.activity_level)}
                      setIsError={setIsError}
                    />
                    {isError.error && isError.name == "activity_level" ? <Error error={isError.msg} /> : ""}
                    <div style={{marginTop: "10px"}}>
                      <Input
                        label="Age*"
                        value={state?.ages}
                        // disableChar={true}
                        handleChange={(val) => handleChange("ages", val)}
                      />
                      {isError.error && isError.name == "ages" ? <Error error={isError.msg} /> : ""}
                    </div>
                  </div>)}
                  
                
              </div>
            </div>
          </div>
          <div className="form-group form-custom mt-3" id="desc">
          <Tabs defaultActiveKey="1" >
            <TabPane tab = {`Description *`} key="1">
              <CkEditor 
                data={content}
                handleChange={(newContent) => {
                  setContent(newContent); 
                  setIscorpAddDesErrorValidation(false)
                }}
              />
            </TabPane>
            {state.package_type == "itinerary" && (<>
            <TabPane tab = {`Itinerary`} key="2">
              <CkEditor 
                  data={state.itinerary}
                  handleChange={(newContent) => setState({...state, itinerary: newContent})}
                />
            </TabPane>
            <TabPane  tab = {`Accomodations`} key="3">
                <CkEditor 
                  data={state.accomodations}
                  handleChange={(newContent) => setState({...state, accomodations: newContent})}
                />
            </TabPane>
            <TabPane tab = {`Essential Info`} key="4">
                <CkEditor 
                  data={state.essential_info}
                  handleChange={(newContent) => setState({...state, essential_info: newContent})}
                />
            </TabPane>
            <TabPane tab = {`Schedule *`} key="5">
              <ScheduleBoxItinerary 
                setPopupSelect={setPopupSelect} 
                state={state}
                isEdit={true}
                />
            </TabPane>
            <TabPane tab = {`FAQ’s`} key="6">
                <CkEditor 
                  data={state.faq}
                  handleChange={(newContent) => setState({...state, faq: newContent})}
                />
            </TabPane>
            </>
            )}
          </Tabs>
          
          {iscorpAddScheduleErrorValidation && state.package_type == "itinerary" && <p style={{ color: "red" }}>{SCHEDULE}</p>}
          {iscorpAddDesErrorValidation && <p style={{ color: "red" }}>{DESCRIPTION}</p>}
          </div>
        </form>
      </div>

      {addHandleRate&&<AddHandleRate 
                 open={addHandleRate}
                 modalClose={() => setAddHandleRate(false)}
                 setPriceList={setPriceList}
                 priceList={priceList}
                 handleAddPrice={handleAddPrice}
                 isAdd={true}
            />}
      {imageSelect && (
        <ImageMultiSelect
          title={state?.pack_title}
          city={state?.location[0]?.city || null}
          images={addImage || []}
          open={imageSelect}
          toggleModal={setImageSelect}
          handleCloseDone={handleCloseImage}
          handleUpdatePackageImage={handleUpdatePackageImage}
          isAddNewEvent={true}
          moduleType="corporate"
          sectionName={dropdownValue?.name}
        />
      )}
    </div>
  );
}
