const BaseURL = "https://hammockbed.appinessworld.com/admin" //test server;
// const BaseURL = "https://hammockstagbed.appinessworld.com/admin"; // staging server
const PopularURL="https://hammockbed.appinessworld.com"

export const API = {
  LOGIN: `${BaseURL}/admin-login/`,
  SESSION_CHECK: `${BaseURL}/session-expiry-check/`,
  CORPLISTING: `${BaseURL}/corporate/listing/list/`,
  ENQUIRIES_LIST: `${BaseURL}/corporate/enquiry/enquiry-list/`,
  ENQUIRIES_FOLLOW_UP_LIST: `${BaseURL}/corporate/enquiry/followup-list/`,
  ENQUIRIES_RESOLVED_LIST: `${BaseURL}/corporate/enquiry/resolved-list/`,
  UPDATE_ENQUIRY_STATUS_API: `${BaseURL}/corporate/enquiry/update/`,
  IMAGE_UPLOAD: `${BaseURL}/corporate/listing/upload-image/`,
  VIDEO_UPLOAD: `${BaseURL}/corporate/upload-video/`,
  IMAGE_DELETE: `${BaseURL}/corporate/listing/image-delete/`,
  POST_CORPORATES: `${BaseURL}/corporate/listing/post/`,
  POST_PACKAGE: `${BaseURL}/corporate/listing/post/`,
  CORP_SEARCH_LISTING: `${BaseURL}/corporate/listing/search/`,
  UPDATE_CORPORATES: `${BaseURL}/corporate/listing/detail-update/`,
  SELECT_DROPDOWN: `${BaseURL}/corporate/section/list-post/`,
  MOVED_PACK_SECTION_ID: `${BaseURL}/corporate/get-section-id/`,
  PACKAGE_DELETE: `${BaseURL}/corporate/listing/pack-delete/`,
  CORPORATE_DELETE: `${BaseURL}/corporate/listing/corp-delete/`,
  PACKAGE_INFO: `${BaseURL}/corporate/listing/detail-update/`,
  SEARCH_ENQUIRIES: `${BaseURL}/corporate/enquiry/search/`,
  CORP_LOCATION_CITY_LIST: `${BaseURL}/corporate/listing/location/`,
  GET_CORPORATE_FEATURED_LIST: `${BaseURL}/corporate/featured/list/`,
  CORPORATE_CSV_DOWNLOAD: `${BaseURL}/corporate/pack-list-download/`,
  CORP_FEATURED_UPDATE: `${BaseURL}/corporate/featured/update/`,

  //Bookings
  BOOKINGS_LIST: `${BaseURL}/corporate/bookings/list/`,
  PARTNER_BOOKINGS_LIST: `${BaseURL}/corporate/bookings/partner-list/`,
  BOOKINGS_CONFIRMED_LIST: `${BaseURL}/corporate/bookings/list/`,
  BOOKINGS_CANCELLED_LIST: `${BaseURL}/corporate/bookings/list/`,
  AVAILABILITY_REQUEST_LIST: `${BaseURL}/corporate/bookings/list/`,
  CORP_BOOKING_FOLLOWUP:`${BaseURL}/corporate/bookings/list/`,
  UPDATE_BOOKING_STATUS: `${BaseURL}/corporate/bookings/update/`,
  SEARCH_BOOKINGS: `${BaseURL}/corporate/bookings/search/`,
  PARTNER_SEARCH_BOOKINGS: `${BaseURL}/corporate/bookings/partner-search/`,
  BOOKINGS_FILTERED_LIST: `${BaseURL}/corporate/bookings/corp-booking-list/`,
  BOOKINGS_CONFIRMED_FILTERED_LIST: `${BaseURL}/corporate/bookings/corp-confirmed-list/`,
  BOOKINGS_CANCELLED_FILTERED_LIST: `${BaseURL}/corporate/bookings/corp-cancelled-list/`,
  VERIFY_BOOKING: `${BaseURL}/events/booking/verify/`,
  CSV_DOWNLOAD: `${BaseURL}/events/export-attendees-csv/`,
  // section
  ADD_SECTION_IMAGES: `${BaseURL}/corporate/section/list-post/`,
  GET_SECTION_IMAGES_DATA: `${BaseURL}/corporate/section/list-post/`,
  DELETE_SECTION_IMAGE: `${BaseURL}/corporate/section/update-delete/`,
  GET_SECTION_PACKAGES: `${BaseURL}/corporate/section/total-pack/`,

  //Accounts
  ACCOUNTS_LIST: `${BaseURL}/corporate/accounts/org-list/`,
  GET_ACCOUNT_INFO: `${BaseURL}/corporate/accounts/organisation/`,
  // UPDATE_ACCOUNT: `${BaseURL}/corporate/accounts/organisation/`,
  UPDATE_ACCOUNT: `${BaseURL}/corporate/accounts/org-update/`,
  POST_ACCOUNT: `${BaseURL}/corporate/accounts/org-create/`,
  UPDATE_POINTS: `${BaseURL}/corporate/accounts/update-points/`,
  ADD_CORP_USER: `${BaseURL}/corporate/accounts/add-account/`,
  UPDATE_EMPLOYEE: `${BaseURL}/corporate/accounts/update-employee/`,
  SEARCH_ORGANISATION: `${BaseURL}/corporate/accounts/org-search/`,
  SEARCH_USER: `${BaseURL}/corporate/accounts/user-search/`,
  EMPLOYEE_LIST: `${BaseURL}/corporate/accounts/user-list/`,
  VERIFY_GIFT_CARD:`${BaseURL}/corporate/bookings/verify-gift-card/`,

  //My family First
  MY_FAMILY_FIRST_REQUESTED: `${BaseURL}/gifts/my-family-first/requested/`,
  MY_FAMILY_FIRST_PLANNING: `${BaseURL}/gifts/my-family-first/planning/`,
  MY_FAMILY_FIRST_CONFIRMED: `${BaseURL}/gifts/my-family-first/confirmed/`,
  MY_FAMILY_FIRST_CANCELLED: `${BaseURL}/gifts/my-family-first/cancelled/`,
  MY_FAMILY_FIRST_SEARCH_REQUESTED: `${BaseURL}/gifts/my-family-first/search/`,
  MY_FAMILY_FIRST_STATUS_UPDATE: `${BaseURL}/gifts/my-family-first/update/`,
  MY_FAMILY_FIRST_STORY_LIST: `${BaseURL}/gifts/my-family-first/story-list-post/`,
  MY_FAMILY_FIRST_STORY_ADD: `${BaseURL}/gifts/my-family-first/story-list-post/`,
  MY_FAMILY_FIRST_STORY_DETAILS: `${BaseURL}/gifts/my-family-first/story-detail-update-del/`,
  MY_FAMILY_FIRST_STORY_DELETE: `${BaseURL}/gifts/my-family-first/story-detail-update-del/`,
  MY_FAMILY_FIRST_STORY_UPDATE: `${BaseURL}/gifts/my-family-first/story-detail-update-del/`,
  MY_FAMILY_FIRST_STORY_SEARCH: `${BaseURL}/gifts/my-family-first/story-search/`,
  MY_FAMILY_FIRST_DELETE_UPDATE_IMAGE: `${BaseURL}/gifts/my-family-first/media-delete/`,

  //event section
  GET_EVENT_SECTION: `${BaseURL}/events/section/list-post/`,
  EVENT_POST_IMAGE_UPLOAD: `${BaseURL}/events/upload-image/`,
  ADD_EVENT_SECTION_LIST_POST: `${BaseURL}/events/section/list-post/`,
  EVENT_SECTION_DELETE: `${BaseURL}/events/section/update-delete/`,
  EVENT_SECTION_PACKAGES: `${BaseURL}/events/section/total-pack/`,

  //Events admin/events/listing/list/
  EVENTS_LIST: `${BaseURL}/events/listing/list/`,
  EVENTS_GET_EDIT: `${BaseURL}/events/listing/detail-update/`,
  EVENTS_UPDATE_EDIT: `${BaseURL}/events/listing/detail-update/`,
  EVENTS_IMAGE_UPLOAD: `${BaseURL}/events/upload-image/`,
  EVENTS_VIDEO_UPLOAD: `${BaseURL}/events/upload-video/`,
  EVENT_ADD_LISTING: `${BaseURL}/events/listing/post/`,
  EVENT_LIST_DROPDOWN: `${BaseURL}/events/section/list-post/`,
  UPDATE_EVENT_BOOKING_STATUS: `${BaseURL}/events/booking/update/`,
  SEARCH_EVENTS_BOOKINGS: `${BaseURL}/events/booking/search/`,
  CANCEL_EVENT: `${BaseURL}/events/listing/cancel/`,
  DELETE_UPDATE_IMAGE: `${BaseURL}/events/listing/image-delete/`,
  EVENT_LISTING_SEARCH: `${BaseURL}/events/listing/search/`,

  // Event bookings
  EVENTS_BOOKING_LIST: `${BaseURL}/events/booking/detail/`,
  UPCOMING_BOOKINGS: `${BaseURL}/events/booking/list/`,
  PAST_BOOKINGS: `${BaseURL}/events/booking/list-past/`,
  CANCELLED_BOOKINGS: `${BaseURL}/events/booking/list-cancelled/`,
  USER_BOOKINGS: `${BaseURL}/events/booking/user-list/`,
  USER_SEARCH_BOOKINGS: `${BaseURL}/events/booking/user-search/`,

  //Gift Voucher
  SEND_GIFT_PURCHASED: `${BaseURL}/gifts/send-gift/purchased/`,
  SEND_GIFT_PLANNING: `${BaseURL}/gifts/send-gift/planning/`,
  SEND_GIFT_CONFIRMED: `${BaseURL}/gifts/send-gift/confirmed/`,
  SEND_GIFT_CANCELLED: `${BaseURL}/gifts/send-gift/cancelled/`,
  SEND_GIFT_SEARCH: `${BaseURL}/gifts/send-gift/search/`,
  SEND_GIFT_UPDATE: `${BaseURL}/gifts/send-gift/update/`,

  //Holiday
  //*********** section *************//
  GET_HOLIDAY_SECTION_LIST: `${BaseURL}/holidays/section/list-post/`,
  ADD_HOLIDAY_SECTION_LIST_POST: `${BaseURL}/holidays/section/list-post/`,
  DELETE_HOLIDAY_SECTION: `${BaseURL}/holidays/section/update-delete/`,
  GET_HOLIDAY_SECTION_PACKAGES: `${BaseURL}/holidays/section/total-pack/`,
  
  //Holiday Listing
  GET_HOLIDAYS_FEATURED_LIST: `${BaseURL}/holidays/featured/list/`,
  HOLIDAY_LIST: `${BaseURL}/holidays/listing/list/`,
  HOLIDAY_ADD_LISTING: `${BaseURL}/holidays/listing/post/`,
  HOLIDAY_EDIT_UPDATE: `${BaseURL}/holidays/listing/update/`,
  UPDATE_HOLIDAY_LIST: `${BaseURL}/holidays/listing/update/`,
  HOLIDAY_IMAGE_UPLOAD: `${BaseURL}/holidays/upload-image/`,
  HOLIDAY_VIDEO_UPLOAD: `${BaseURL}/holidays/upload-video/`,
  HOLIDAY_PACKAGE_INFO: `${BaseURL}/holidays/listing/update/`,
  HOLIDAY_DELETE: `${BaseURL}/holidays/listing/holiday-delete/`,
  HOLIDAY_DELETE_PACKAGE: `${BaseURL}/holidays/listing/pack-delete/`,
  HOLIDAY_POST_PACKAGE: `${BaseURL}/holidays/listing/post/`,
  HOLIDAY_SELECT_DROPDOWN: `${BaseURL}/holidays/section/list-post`,
  HOLIDAY_SEARCH: `${BaseURL}/holidays/listing/search/`,
  HOLIDAY_IMAGE_DELETE: `${BaseURL}/holidays/listing/image-delete/`,
  GET_LOCATION_DROPDOWN: `${BaseURL}/holidays/listing/location/`,
  HOLIDAYS_CSV_DOWNLOAD: `${BaseURL}/holidays/pack-list-download/`,
  HOLIDAYS_FEATURED_UPDATE: `${BaseURL}/holidays/featured/update/`,
  HOLIDAYS_PACK_COPY_TO_CORP: `${BaseURL}/holidays/holidaycopy/`,
  HOLIDAYS_PACK_COPY_TO_CORP_FALSE:`${BaseURL}/holidays/update-copy-corporate/`,

  //*********** Request *************//
  GET_HOLIDAY_REQUEST_LIST: `${BaseURL}/holidays/request/list/`,
  GET_HOLIDAY_REQUEST_SEARCH: `${BaseURL}/holidays/request/search/`,
  HOLIDAY_REQUEST_STATUS_UPDATE: `${BaseURL}/holidays/request/update/`,
  //*************  Reviews ****************** */
  GET_HOLIDAY_REVIEW_LIST:`${BaseURL}/holidays/review-list/`,
  HOLIDAY_REVIEW_STATUS_UPDATE:`${BaseURL}/holidays/review-update/`,
  //*************  Booking ****************** */
  GET_HOLIDAY_BOOKING: `${BaseURL}/holidays/booking/list/`,
  HOLIDAY_BOOKING_SEARCH: `${BaseURL}/holidays/booking/search/`,
  HOLIDAY_BOOKING_STATUS_UPDATE: `${BaseURL}/holidays/booking/update/`,

  //Experiences
  //*********** Section *************//
  GET_EXPERIENCES_SECTION: `${BaseURL}/experience/section/list-post/`,
  EXPERIENCE_POST_IMAGE_UPLOAD: `${BaseURL}/experience/upload-image/`,
  ADD_EXPERIENCE_SECTION_LIST_POST: `${BaseURL}/experience/section/list-post/`,
  EXPERIENCE_SECTION_DELETE: `${BaseURL}/experience/section/update-delete/`,
  GET_EXPERIENCE_SECTION_PACKAGES: `${BaseURL}/experience/section/total-pack/`,

  //*************  Booking ****************** */
  GET_EXPERIENCE_BOOKING: `${BaseURL}/experience/booking/list/`,
  EXPERIENCE_BOOKING_STATUS_UPDATE: `${BaseURL}/experience/booking/update/`,
  EXPERIENCE_BOOKING_SEARCH: `${BaseURL}/experience/booking/search/`,

  //*********** Listings *************//
  GET_EXPERIENCES_LISTING: `${BaseURL}/experience/listing/list/`,
  // EXPERIENCES_PACKLIST_DOWNLOAD: `${BaseURL}/experience/pack-list-download/`,
  GET_EXPERIENCES_FEATURED_LIST: `${BaseURL}/experience/featured/list/`,
  EXPERIENCES_IMAGE_UPLOAD: `${BaseURL}/experience/upload-image/`,
  EXPERIENCES_VIDEO_UPLOAD: `${BaseURL}/experience/upload-video/`,
  UPDATE_EXPERIENCES: `${BaseURL}/experience/listing/detail-update/`,
  EXPERIENCES_SEARCH_LISTING: `${BaseURL}/experience/listing/search/`,
  POST_EXPERIENCES_LISTING: `${BaseURL}/experience/listing/post/`,
  POST_EXPERIENCES_PACKAGE: `${BaseURL}/experience/listing/post/`,
  EXPERIENCES_SELECT_DROPDOWN: `${BaseURL}/experience/section/list-post/`,
  EXPERIENCES_PACKAGE_DELETE: `${BaseURL}/experience/listing/pack-delete/`,
  EXPERIENCES_IMAGE_DELETE: `${BaseURL}/experience/listing/image-delete/`,
  EXPERIENCES_DELETE: `${BaseURL}/experience/listing/experience-delete/`,
  EXPERIENCES_PACKAGE_INFO: `${BaseURL}/experience/listing/detail-update/`,
  EXPERIENCE_CSV_DOWNLOAD: `${BaseURL}/experience/pack-list-download/`,
  EXPERIENCE_FEATURED_UPDATE: `${BaseURL}/experience/featured/update/`,
  EXPERIENCE_PACK_COPY_TO_CORP: `${BaseURL}/experience/experiencecopy/`,
  EXPERIENCE_PACK_COPY_TO_CORP_FALSE:`${BaseURL}/experience/update-copy-corporate/`,
  GET_EXPERIENCES_REVIEW_LIST:`${BaseURL}/experience/review-list/`,
  EXPERIENCES_REVIEW_STATUS_UPDATE:`${BaseURL}/experience/review-update/`,
  //*********** Testimonial *************//
  GET_TESTIMONIAL_LIST: `${BaseURL}/events/testimonial/list/`,
  POST_TESTIMONIAL: `${BaseURL}/events/testimonial/post/`,
  UPDATE_TESTIMONIAL: `${BaseURL}/events/testimonial/update/`,
  DELETE_TESTIMONIAL: `${BaseURL}/events/testimonial/delete/`,

  //*********Corporate Testimonial************//
  GET_CORPORATE_TESTIMONIAL_LIST: `${BaseURL}/corporate/testimonial/list/`,
  CORP_POST_TESTIMONIAL: `${BaseURL}/corporate/testimonial/post/`,
  UPDATE_CORP_TESTIMONIAL: `${BaseURL}/corporate/testimonial/update/`,
  DELETE_CORP_TESTIMONIAL: `${BaseURL}/corporate/testimonial/delete/`,

  //*********holiday*********** */
  GET_HOLIDAY_TESTIMONIAL_LIST: `${BaseURL}/holidays/testimonial/list/`,
  HOLIDAY_POST_TESTIMONIAL: `${BaseURL}/holidays/testimonial/post/`,
  UPDATE_HOLIDAY_TESTIMONIAL: `${BaseURL}/holidays/testimonial/update/`,
  DELETE_HOLIDAY_TESTIMONIAL: `${BaseURL}/holidays/testimonial/delete/`,
  //*********holiday*********** */
  // Pack reviews
  CREATE_DELETE_UPDATE_HOLIDAY_REVIEWS: `${BaseURL}/holidays/admin-review-manage/`,
  CREATE_DELETE_UPDATE_CORPORATE_REVIEWS: `${BaseURL}/corporate/admin-review-manage/`,
  CREATE_DELETE_UPDATE_EXPERIENCE_REVIEWS: `${BaseURL}/experience/admin-review-manage/`,
  

  //*******experience********** */
  GET_EXP_TESTIMONIAL_LIST: `${BaseURL}/experience/testimonial/list/`,
  EXP_POST_TESTIMONIAL: `${BaseURL}/experience/testimonial/post/`,
  UPDATE_EXP_TESTIMONIAL: `${BaseURL}/experience/testimonial/update/`,
  DELETE_EXP_TESTIMONIAL: `${BaseURL}/experience/testimonial/delete/`,
  //****Users contact list */
  DOWNLOAD_USERS_CONTACT_LIST: `${BaseURL}/account/export-user-list/`,
  //*****subscribe count */
  SUBSCRIBERS_COUNT: `${BaseURL}/account/subscribe-count/`,

  //******partners */
  PARTNERS_LIST: `${BaseURL}/corporate/accounts/partner-list/`,
  PARTNER_INFO: `${BaseURL}/corporate/accounts/partner-view/`,
  CREATE_PARTNER: `${BaseURL}/corporate/accounts/partner-create/`,
  UPDATE_PARTNER: `${BaseURL}/corporate/accounts/partner-update/`,
  REGENERATE_TOKEN: `${BaseURL}/corporate/accounts/partner-regenerate/`,
  SEARCH_PARTNER: `${BaseURL}/corporate/accounts/partner-search/`,

  //****CORP REVIEWS */
  GET_CORPORATES_REVIEW_LIST:`${BaseURL}/corporate/review-list/`,
  CORPORATES_REVIEW_STATUS_UPDATE:`${BaseURL}/corporate/review-update/`,
  
  //WEDDING
  WEDDING_STORY_LIST: `${BaseURL}/weddings/getStories/`,
  WEDDING_STORY_ADD: `${BaseURL}/weddings/createStory/`,
  WEDDING_STORY_DETAILS: `${BaseURL}/weddings/getStory/`,
  WEDDING_STORY_DELETE: `${BaseURL}/weddings/deleteStory/`,
  WEDDING_STORY_UPDATE: `${BaseURL}/weddings/updateStory/`,
  WEDDING_STORY_SEARCH: `${BaseURL}/weddings/searchStory/`,
  WEDDING_REQUEST : `${BaseURL}/weddings/getWeddingPlanRequests/`,
  WEDDING_REQUEST_SEARCH : `${BaseURL}/weddings/searchBooking/`,
  WEDDING_REQUEST_UPDATE : `${BaseURL}/weddings/booking/update/`,
  WEDDING_DESTINATION_LIST : `${BaseURL}/weddings/getWeddingDestinations/`,
  WEDDING_DESTINATION_CREATE : `${BaseURL}/weddings/createWeddingDestination/`,
  WEDDING_DESTINATION_UPDATE : `${BaseURL}/weddings/updateWeddingDestination/`,
  WEDDING_DESTINATION_DELETE : `${BaseURL}/weddings/deleteWeddingDestination/`,
  WEDDING_IMAGE_VIDEO_UPLOAD : `${BaseURL}/weddings/uploadAsset/`,
  WEDDING_IMAGE_VIDEO_DELETE : `${BaseURL}/weddings/deleteWeddingsMedia/`,

  //HONEYMOON
  HONEYMOON_STORY_LIST: `${BaseURL}/honeymoon/getStories/`,
  HONEYMOON_STORY_ADD: `${BaseURL}/honeymoon/createStory/`,
  HONEYMOON_STORY_DETAILS: `${BaseURL}/honeymoon/getStory/`,
  HONEYMOON_STORY_DELETE: `${BaseURL}/honeymoon/deleteStory/`,
  HONEYMOON_STORY_UPDATE: `${BaseURL}/honeymoon/updateStory/`,
  HONEYMOON_STORY_SEARCH: `${BaseURL}/honeymoon/searchStory/`,
  HONEYMOON_REQUEST : `${BaseURL}/honeymoon/honeymoonPlanRequests/`,
  HONEYMOON_REQUEST_SEARCH : `${BaseURL}/honeymoon/searchBooking/`,
  HONEYMOON_REQUEST_UPDATE : `${BaseURL}/honeymoon/booking/update/`,
  HONEYMOON_IMAGE_VIDEO_UPLOAD : `${BaseURL}/honeymoon/uploadAsset/`,
  HONEYMOON_IMAGE_VIDEO_DELETE : `${BaseURL}/honeymoon/deleteHoneymoonMedia/`,
  HONEYMOON_DESTINATION_LIST : `${BaseURL}/honeymoon/getHoneymoonDestinations/`,
  HONEYMOON_DESTINATION_CREATE : `${BaseURL}/honeymoon/createHoneymoonDestination/`,
  HONEYMOON_DESTINATION_UPDATE : `${BaseURL}/honeymoon/updateHoneymoonDestination/`,
  HONEYMOON_DESTINATION_DELETE : `${BaseURL}/honeymoon/deleteHoneymoonDestination/`,

  //ANNIVERSARY
  ANNIVERSARY_STORY_LIST: `${BaseURL}/anniversary/getStories/`,
  ANNIVERSARY_STORY_ADD: `${BaseURL}/anniversary/createStory/`,
  ANNIVERSARY_STORY_DETAILS: `${BaseURL}/anniversary/getStory/`,
  ANNIVERSARY_STORY_DELETE: `${BaseURL}/anniversary/deleteStory/`,
  ANNIVERSARY_STORY_UPDATE: `${BaseURL}/anniversary/updateStory/`,
  ANNIVERSARY_STORY_SEARCH: `${BaseURL}/anniversary/searchStory/`,
  ANNIVERSARY_REQUEST : `${BaseURL}/anniversary/anniversaryPlanRequests/`,
  ANNIVERSARY_REQUEST_SEARCH : `${BaseURL}/anniversary/searchBooking/`,
  ANNIVERSARY_REQUEST_UPDATE : `${BaseURL}/anniversary/booking/update/`,
  ANNIVERSARY_IMAGE_VIDEO_UPLOAD : `${BaseURL}/anniversary/uploadAsset/`,
  ANNIVERSARY_IMAGE_VIDEO_DELETE : `${BaseURL}/anniversary/deleteAnniversaryMedia/`,
  ANNIVERSARY_DESTINATION_LIST : `${BaseURL}/anniversary/getAnniversaryDestinations/`,
  ANNIVERSARY_DESTINATION_CREATE : `${BaseURL}/anniversary/createAnniversaryDestination/`,
  ANNIVERSARY_DESTINATION_UPDATE : `${BaseURL}/anniversary/updateAnniversaryDestination/`,
  ANNIVERSARY_DESTINATION_DELETE : `${BaseURL}/anniversary/deleteAnniversaryDestination/`,

  //PAYMENT LINK
  CREATE_INTERNAL_PACKAGE : `${BaseURL}/internalpack/create-internal-package/`,
  PAYMENT_BOOKINGS_API : `${BaseURL}/internalpack/list-booked-internal-package/`,
  GENERATE_LINK : `${BaseURL}/internalpack/get-advance-paid-package/`,
  OFFLINE_COLLECT_EDIT : `${BaseURL}/internalpack/offline-collect-and-edit/`,
  GENERATE_ADVANCE_LINK: `${BaseURL}/internalpack/get-package-details/`,

  //WEBSTORIES LINK
  ADD_IMAGE_STORIES:`${BaseURL}/webstories/upload-media/`,
  ADD_WEBSTORIES:`${BaseURL}/webstories/api/`,
  WEB_STORIES_LISTS:`${BaseURL}/webstories/api/`,
  UPDATE_WEBSTORIES:`${BaseURL}/webstories/api/`,

  // Popular Destination 

  ADD_POPULAR_DESTINAIONS:`${PopularURL}/global/popular-destinations/`,
  STATE_COUNTRY_LISTS:`${PopularURL}/global/list-states-and-countries/`,

  // call back
  CALL_BACK_LIST:`${PopularURL}/user/account/contact-us/`,

  // refund
  REFUND_BOOKED_PACKAGE:`${PopularURL}/user/payment/refund-voucher/`,
};
