import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { myFamilyFirstPlanning, myFamilyFirstConfirmed, myFamilyFirstCancelled, familyFirstEndDate, familyFirstStartDate, 
  myFamilyFirstRequested, familyFirstSearchRequested, setSearchTerm
} from '../../../actions/myFamilyFirstAction'
import { Tabs } from "antd";
import Datefilter from '../../../common/dateFilter'
import Requested from '../myFamilyFirst/requested';
import Planning from '../myFamilyFirst/planning';
import Confirmed from '../myFamilyFirst/confirm';
import Cancelled from '../myFamilyFirst/cancelled';
import { FAMILY_REQUEST } from '../../../utils/constants';
import { Spinner } from 'react-bootstrap';
const { TabPane } = Tabs;

const Index = () => {
  const [ status, setStatus ] = useState("requested");
  const dispatch = useDispatch();
  const SEARCH_TERM = useSelector(state => state.myFamilyFirst.searchTerm);
  const totalRequestedResults = useSelector(state => state.myFamilyFirst.requestedTotalResults);
  const totalPlanningResults = useSelector(state => state.myFamilyFirst.planningTotalResults);
  const totalConfirmedResults = useSelector(state => state.myFamilyFirst.confirmedTotalResults);
  const totalCancelledResults = useSelector(state => state.myFamilyFirst.cancelledTotalResults);
  const isDataLoading = useSelector(
    (state) => state.holidayListing.isDataLoading
  );
  useEffect(() => {
    dispatch(myFamilyFirstRequested());
    dispatch(myFamilyFirstPlanning());
    dispatch(myFamilyFirstConfirmed());
    dispatch(myFamilyFirstCancelled());
  }, []);

  useEffect(() => {
    dispatch(setSearchTerm(''))
    if(status === "requested") {
      dispatch(familyFirstSearchRequested(1,"", 'requested'));
    }
    else if(status === "planning") {
      dispatch(familyFirstSearchRequested(1,"", 'planning'));
    }
    else if(status === "confirmed") {
      dispatch(familyFirstSearchRequested(1,"", 'confirmed'));
    }
    else if(status === "cancelled") {
      dispatch(familyFirstSearchRequested(1,"", 'cancelled'));
    }
  }, [status]);

  const handleTabChange = (key) => {
    if(key === '2') {
      setStatus('planning')
    }
    else if(key === '3') {
      setStatus('confirmed')
    }
    else if(key === '4') {
      setStatus('cancelled')
    }
    else {
      setStatus('requested')
    }
  };
  
  const overallFamilyRequest = 
  totalRequestedResults+ 
  totalPlanningResults+ 
  totalConfirmedResults +
  totalCancelledResults; 

  useEffect(() => {
    return () => {
      dispatch(familyFirstStartDate(null));
      dispatch(familyFirstEndDate(null))
    }
  }, []);

  return (
    <>
    <div style={{ position: "absolute", right: 50 }}>
      <Datefilter status={status}/>
    </div>
    <div className="enquiry-tabs-holder">
      <p className="title-holder">{overallFamilyRequest} {FAMILY_REQUEST}</p>
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={`${totalRequestedResults} Requested`} key="1">
          <Requested /> 
        </TabPane>
        <TabPane tab={`${totalPlanningResults} Planning`} key="2">
          <Planning /> 
        </TabPane>
        <TabPane tab={`${totalConfirmedResults} Confirmed`} key="3">
          <Confirmed  /> 
        </TabPane>
        <TabPane tab={`${totalCancelledResults} Cancelled`} key="4">
          <Cancelled /> 
        </TabPane>
      </Tabs>
      {isDataLoading &&
            <p className="loding-spinner">
              <Spinner animation="border" variant="primary" />
            </p>
          }
      <div>
    </div>
    </div>
  </> 
  )
};

export default Index;